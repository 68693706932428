<template>
    <div class="row">
        <div class="col-12 pr-0 pt-2 pl-4">
            <!-- <b-card
                title=""
                class="">
                <div class="row">
                  <div class="col-4">
                    <select class="form-control" v-model="currentSelectedProject">
                      <option value="" disabled selected>Please select a project</option>
                      <option v-for="project in projectList"  :key="project.id" :value="project.code">{{project.name}}</option>
                    </select>
                  </div>
                </div>
                
            </b-card> -->
            <b-card
                title=""
                class="m-2 p-0"
                no-body>
                <template #header>
                  <div class="row">
                    <div class="col">
                      <h1 class="mb-0">Project Dashboard</h1>
                    </div>
                    <div class="col-4">
                      <select class="form-control" v-model="currentSelectedProject">
                          <option value="" disabled selected>Please select a project</option>
                          <option v-for="project in projectList"  :key="project.id" :value="project.code">{{project.name}}</option>
                        </select>
                    </div>
                  </div>
                  
                  
                </template>
            </b-card>
                  <!-- <b-card
                    title=""
                    class="">
                        <select class="form-control" v-model="currentSelectedProject">
                          <option value="" disabled selected>Please select a project</option>
                          <option v-for="project in projectList"  :key="project.id" :value="project.code">{{project.name}}</option>
                        </select>
                    
                  </b-card> -->

            <div class="row mb-1">
                <div class="col">
                  
                  
                    <b-card
                          title=""
                          >
                          <div :id="'charts'+currentSelectedProject" v-if="dataLoadedFlag">
                            <RisksProportionChart
                              :inputProjectCode="currentSelectedProject"
                              :key="currentSelectedProject"
                              :projCRADetails="projCRADetails"
                              title="Risks proportion by time and rating"
                            />
                          </div>
                          <div v-else>
                            No data found
                          </div>
                      </b-card>
                  
                </div>
                <div class="col">
                    <b-card
                        title=""
                        >
                        <div :id="'charts'+currentSelectedProject" v-if="dataLoadedFlag">
                          <ClimateHazardsMediumToExtremeChartTest
                              :inputProjectCode="currentSelectedProject"
                              :key="currentSelectedProject"
                              :projCRADetails="projCRADetails"
                              title="Sources (climate hazards) of medium high and very high risks"
                            />
                        </div>
                        <div v-else>
                          No data found
                        </div>
                    </b-card>
                </div>
                <div class="col">
                    <b-card
                        title=""
                        >
                        <div :id="'charts'+currentSelectedProject" v-if="dataLoadedFlag">
                          <AssetElementsMediumToExtremeChart
                              :inputProjectCode="currentSelectedProject"
                              :key="currentSelectedProject"
                              :projCRADetails="projCRADetails"
                              title="Asset components with medium high and very high risks"
                            />
                        </div>
                        <div v-else>
                          No data found
                        </div>
                    </b-card>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-card
                        title=""
                        >
                        <div :id="'charts'+currentSelectedProject" v-if="dataLoadedFlag">
                          <!-- <NoRisksAAIChart
                            :projectCRAEntryList="projectCRAEntryList"
                          /> -->
                          <div class="row">
                              <div class="col text-right">
                                <b-button 
                                  variant="outline-secondary"
                                  size="sm"
                                  v-b-popover.hover="'Total number of adaptation actions implemented across the ESC program'" >
                                  <i class="fas fa-question-circle"></i>
                                </b-button>
                              </div>
                          </div>
                          <div class="row m-0">
                            <div class="col m-0">
                              
                              <h5 class="font-weight-bold card-title text-secondary mb-0"><i class="fa fa-chart-line"></i> Adaptation Actions</h5>
                              <br>
                                <div class="row m-0">
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary justify-content-center">Timing</span>
                                    </div>
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary justify-content-center">Count</span>
                                    </div>
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary justify-content-center">Implemented</span>
                                    </div>
                                    <div class="col">
                                        <span class=" font-weight-bold text-secondary ml-4 pl-4 justify-content-center">%</span>
                                    </div>
                                </div>
                                <br>
                              <div v-for="(item, index) in withAdaptationImplemented" :key="index">
                                <div class="row mb-1">
                                  <div class="col d-flex justify-content-center">
                                    <span class=" text-secondary">{{ item.label }}</span>
                                  </div>
                                  <div class="col d-flex justify-content-center">
                                    <span class="h5 font-weight-bold mb-0">{{ item.adaptationActionTotal }}</span>
                                  </div>
                                  <div class="col d-flex justify-content-center">
                                    <span class="h5 font-weight-bold mb-0">{{ item.adaptationActionImplemented }}</span>
                                  </div>
                                  <div class="col d-flex justify-content-center">
                                    <span class="h5 font-weight-bold mb-0 " :class="(item.adaptationActionImplementedPercentage<30?'text-danger':(item.adaptationActionImplementedPercentage<70?'text-warning':'text-success'))">{{ item.adaptationActionImplementedPercentage.toFixed(2)}}</span>
                                  </div>
                                </div>
                                
                              </div>
                              

                            </div>
                            <div class="col-auto">
                              
                            </div>
                          </div>
                          
                        </div>
                        <div v-else>
                          No data found
                        </div>
                    </b-card>
                </div>
                <div class="col">
                    <b-card
                        title=""
                        >
                        <div :id="'charts'+currentSelectedProject" v-if="dataLoadedFlag">
                          <ClimateHazardsMediumToExtremeChart
                              :inputProjectCode="currentSelectedProject"
                              :key="currentSelectedProject"
                              :projCRADetails="projCRADetails"
                              title="Sources (climate hazards) of medium high and very high risks"
                          />
                        </div>
                        <div v-else>
                          No data found
                        </div>
                    </b-card>
                </div>
                <div class="col" >
                  
                    <b-card
                        title=""
                        >
                        <div :id="'charts'+currentSelectedProject" v-if="dataLoadedFlag">
                          <RiskRatingAdaptationChart
                          :key="currentSelectedProject"
                          :inputProjectCode="currentSelectedProject"
                          :projCRADetails="projCRADetails"
                          title="Risk rating pre/post adaptation"
                          />
                        </div>
                        <div v-else>
                          No data found
                        </div>
                    </b-card>

                  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import BarChart from './charts/BarChart'
import DoughnutChart from './charts/DoughnutChart'
import PieChart from './charts/PieChart'
import HighRiskChart from './charts/HighRiskChart'
import RisksProportionChart from './charts/RisksProportionChart'
import ClimateHazardsMediumToExtremeChart from './charts/ClimateHazardsMediumToExtremeChart'
import ClimateHazardsMediumToExtremeChartTest from './charts/ClimateHazardsMediumToExtremeChartTest'
import AssetElementsMediumToExtremeChart from './charts/AssetElementsMediumToExtremeChart'
import NoRisksAAIChart from './charts/NoRisksAAIChart'
import RiskRatingAdaptationChart from './charts/RiskRatingAdaptationChart'

export default {
  name: 'ProjectReportingDashboard',
  components: {
    BarChart,
    DoughnutChart,
    PieChart,
    HighRiskChart,
    RisksProportionChart,
    ClimateHazardsMediumToExtremeChart,
    ClimateHazardsMediumToExtremeChartTest,
    AssetElementsMediumToExtremeChart,
    NoRisksAAIChart,
    RiskRatingAdaptationChart
  },
  data() {
    return {
      currentSelectedProject: "WHPW",
      barChartData: [],
      testInputData: [],
      testInputConfig: {
        backgroundColor: "teal"
      },
      barChart2Data: {
        datasets:[],
        labels: []
      },
      projCRADetails: [],
      dataLoadedFlag: false,
      isLoading: false,
      projectCRAEntryList: null
    }
  },
  computed: {
      projectList: {
          get() {
              return this.$store.state.mainModule.projectList
          } 
      },
      craEntryList: {
        get() {
          
          return this.$store.state.mapModule.craEntryList
        }
      },
      // projectCRAEntryList: {
      //   get() {
      //     if(this.currentSelectedProject == null){
      //       this.currentSelectedProject = "BRBR"
      //     }
      //     console.log(this.currentSelectedProject)
      //     console.log(this.$store.state.mapModule.craEntryList)
      //     console.log(this.$store.state.mapModule.craEntryList.filter(obj=>{return obj.project.code == this.currentSelectedProject})[0].entries)
      //     return this.$store.state.mapModule.craEntryList.filter(obj=>{return obj.project.code == this.currentSelectedProject})[0].entries
      //     // if(this.currentSelectedProject != null){
      //     //   // this.dataLoadedFlag = false
      //     //   return this.$store.state.mapModule.craEntryList.filter(obj=>{return obj.project.code == this.currentSelectedProject})[0].entries
      //     // }
      //     // else{
      //     //   // this.currentSelectedProject = "BRBR"
      //     //   return []
      //     // }
      //   }
      // },
      withAdaptationImplemented: {
        get() {
        //     console.log(this.projectCRAEntryList);
        //   let adaptationImplemented = []
            // get counter variables per item
            let adaptationItemsObject = {
                design: {
                    adaptationActionTotal: 0,
                    adaptationActionImplemented: 0,
                    adaptationActionImplementedPercentage: 0,
                    label: "Design"
                },
                construction: {
                    adaptationActionTotal: 0,
                    adaptationActionImplemented: 0,
                    adaptationActionImplementedPercentage: 0,
                    label: "Construction"
                },
                '2030': {
                    adaptationActionTotal: 0,
                    adaptationActionImplemented: 0,
                    adaptationActionImplementedPercentage: 0,
                    label: "2030"
                },
                '2050': {
                    adaptationActionTotal: 0,
                    adaptationActionImplemented: 0,
                    adaptationActionImplementedPercentage: 0,
                    label: "2050"
                },
                '2070': {
                    adaptationActionTotal: 0,
                    adaptationActionImplemented: 0,
                    adaptationActionImplementedPercentage: 0,
                    label: "2070"
                },
                '2090': {
                    adaptationActionTotal: 0,
                    adaptationActionImplemented: 0,
                    adaptationActionImplementedPercentage: 0,
                    label: "2090"
                }
            }
          let adaptationItems = this.projectCRAEntryList.filter(obj=>obj.adaptationActions.length > 0).map(obj=>obj.adaptationActions).flat(1)
          adaptationItems.forEach(item=>{
            if (item.implementation_timing=='Design') {
                adaptationItemsObject.design.adaptationActionTotal += 1
                if (item.is_implemented==1) {
                    adaptationItemsObject.design.adaptationActionImplemented += 1
                }
            }
            else if (item.implementation_timing=='Construction') {
                adaptationItemsObject.construction.adaptationActionTotal += 1
                if (item.is_implemented==1) {
                    adaptationItemsObject.construction.adaptationActionImplemented += 1
                }
            }
            else if (item.implementation_timing=='2030') {
                adaptationItemsObject['2030'].adaptationActionTotal += 1
                if (item.is_implemented==1) {
                    adaptationItemsObject['2030'].adaptationActionImplemented += 1
                }
            }
            else if (item.implementation_timing=='2050') {
                adaptationItemsObject['2050'].adaptationActionTotal += 1
                if (item.is_implemented==1) {
                    adaptationItemsObject['2050'].adaptationActionImplemented += 1
                }
            }
            else if (item.implementation_timing=='2070') {
                adaptationItemsObject['2070'].adaptationActionTotal += 1
                if (item.is_implemented==1) {
                    adaptationItemsObject['2070'].adaptationActionImplemented += 1
                }
            }
            else if (item.implementation_timing=='2090') {
                adaptationItemsObject['2090'].adaptationActionTotal += 1
                if (item.is_implemented==1) {
                    adaptationItemsObject['2090'].adaptationActionImplemented += 1
                }
            }
          })
          Object.keys(adaptationItemsObject).forEach(adaptationItemObjectKey=>{
            let adaptationItemObject = adaptationItemsObject[adaptationItemObjectKey]
            if (adaptationItemObject.adaptationActionTotal > 0) {
                adaptationItemObject.adaptationActionImplementedPercentage = (adaptationItemObject.adaptationActionImplemented / adaptationItemObject.adaptationActionTotal) * 100;
                adaptationItemObject.adaptationActionImplementedPercentage.toFixed(2)
            }
            else {
                adaptationItemObject.adaptationActionImplementedPercentage = 0
            }
          })
          console.log(adaptationItemsObject);
          return adaptationItemsObject
        }
      },
      // currentProjectCRAEntryList: {
      //     get() {
      //       return this.$store.state.mapModule.currentProjectCRAEntryList
              
      //     }
      // },
  },
  // watch: {
  //   currentSelectedProject: function(newVal, oldVal) { // watch it
  //     // this.$store.commit('mainModule/setCurrentProject', newVal)
  //     // this.$store.commit("mapModule/setCurrentProjectCRAEntries", newVal)
  //   }
  // },
  watch: {
    currentSelectedProject: function(){
      const current = this
      console.log("went here currentSelectedProject")
      current.dataLoadedFlag = false
      current.projectCRAEntryList = current.craEntryList.filter(obj=>{return obj.project.code == current.currentSelectedProject})[0].entries
    //   current.setProjectCRADetails(current, current.projectCRAEntryList)
    //   console.log(current.currentSelectedProject)
        // current.fetchFormStatusList().then(result=>{
        //   current.setGroupDetails(current, result.data)
        // });
    },
    craEntryList: function(){
      const current = this
      console.log("went here craEntryList")

      if(this.currentSelectedProject == null || this.currentSelectedProject == ""){
        this.currentSelectedProject = "WHPW"
      }
      current.projectCRAEntryList = current.craEntryList.filter(obj=>{return obj.project.code == current.currentSelectedProject})[0].entries
    //   current.setProjectCRADetails(current, current.projectCRAEntryList)
      
    },
    projectCRAEntryList: function() {
        const current = this
        console.log(current.currentSelectedProject);
        console.log(current.projectCRAEntryList);
        current.setProjectCRADetails(current, current.projectCRAEntryList)
    }
  },
  methods: {
    setProjectCRADetails: function(current, data) {
        current.projCRADetails = []
        data.forEach((projCRADetails, index)=>{
          current.projCRADetails.push(projCRADetails)
        })
        current.dataLoadedFlag = true
        current.isLoading = false
      },
  },
  mounted () {
    const current = this
    // current.currentSelectedProject = "BRBR"
    if (this.craEntryList.length > 0) {
        current.projectCRAEntryList = current.craEntryList.filter(obj=>{return obj.project.code == current.currentSelectedProject})[0].entries
    }
    // current.setProjectCRADetails(current, current.projectCRAEntryList)
  },
  beforeMount() {
    // const current = this
    // current.currentSelectedProject = "BRBR"
    // current.setProjectCRADetails(current, current.projectCRAEntryList)
    // var dataset = {
    //   label:"Sample",
    //   backgroundColor: "teal",
    //   data:[],
    // }
    // for (var i = 0; i < 10; i++) {
    //   dataset.data.push(Math.floor(Math.random()*100))
    //   this.barChart2Data.labels.push(i)
    // }
    // this.barChart2Data.datasets.push(dataset)
  }
}
</script>

<style scoped>
.text-orange {
  color: rgb(255, 136, 0);
}
</style>