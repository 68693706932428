<template>
    <div class="row landingPage">
        <div class="col-10 pr-0 pt-2 pl-4">
            <b-card
                title="About"
                class=""
                body-class="p-2">
                <b-card-text>
                    {{ aboutText }}
                </b-card-text>
            </b-card>
            <div class="row">
                <div class="col pl-2 pr-0">
                    <b-card
                        class="m-2"
                        body-class=""
                        >
                            <b-card-title>
                                CRA Tool: User Guide
                                <a :href="userGuideLink" v-b-popover.hover.right="'User Guide link'" target='_blank'>
                                    <i class="fa fa-paperclip"></i>
                                </a>
                            </b-card-title>
                            <b-img class='p-0' src="/img/UserGuide.png" fluid alt="Fluid image"></b-img>
                            <!-- <b-card-img class='p-0' src="/img/UserGuide.png"></b-card-img> -->
                        <!-- <b-card-text>
                            <h6>
                                {{ userGuide.header }}
                            </h6>
                            
                            <b-list-group>
                                <b-list-group-item v-for="(item,index) in userGuide.content" :key="index">{{ item }}</b-list-group-item>
                            </b-list-group>
                        </b-card-text> -->
                    </b-card>
                </div>
                <div class="col pr-2 pl-0">
                    <b-card
                        title="Project Navigator"
                        title-tag="h2"
                        class="m-2">
                        <small>
                            <b>
                                Click a project below to access its climate change risk assessment
                            </b>
                        </small>
                        <br>
                        <br>
                        <div class="row">
                            <p class="col-3 mb-0">
                                <small>
                                    <b>
                                        Project stage status key: 
                                    </b>
                                </small>
                            </p>
                            <b-button size="sm" class="p-0 col-2 mr-1" variant="danger" disabled>Not Started</b-button>
                            <b-button size="sm" class="p-0 col-2 mr-1" variant="warning" disabled>In Progress</b-button>
                            <b-button size="sm" class="p-0 col-2 mr-1" variant="success" disabled>Completed</b-button>
                        </div>
                        <br/>
                        <div class="row" v-for="(project,indexProjects) in projectList" :key="indexProjects">
                            <div class="col">
                                <b-button variant="secondary" size="lg" class="mb-2 btn-block"
                                    v-on:click="selectProject(project)">
                                    {{ project.code }}
                                </b-button>
                            </div>
                            <div class="col" v-for="(stage,indexStage) in stages" :key="indexStage">
                                <!-- <b-button variant="success" size="lg" class="btn-block" disabled>
                                    {{ stage.title }}
                                </b-button> -->
                                <template v-if="stage.title == 'Stage 1'">
                                    <b-button :variant="project.stage_1_status=='completed'?'success':(project.stage_1_status=='in_progress'?'warning':'danger')" size="lg" class="btn-block" disabled>
                                        {{ stage.title }}
                                    </b-button>
                                </template>
                                <template v-if="stage.title == 'Stage 2'">
                                    <b-button :variant="project.stage_2_status=='completed'?'success':(project.stage_2_status=='in_progress'?'warning':'danger')" size="lg" class="btn-block" disabled>
                                        {{ stage.title }}
                                    </b-button>
                                </template>
                                <template v-if="stage.title == 'Stage 3'">
                                    <b-button :variant="project.stage_3_status=='completed'?'success':(project.stage_3_status=='in_progress'?'warning':'danger')" size="lg" class="btn-block" disabled>
                                        {{ stage.title }}
                                    </b-button>
                                </template>
                                <template v-if="stage.title == 'Stage 4'">
                                    <b-button :variant="project.stage_4_status=='completed'?'success':(project.stage_4_status=='in_progress'?'warning':'danger')" size="lg" class="btn-block" disabled>
                                        {{ stage.title }}
                                    </b-button>
                                </template>
                                <!-- <template v-if="stage.status == 'onhold'">
                                    <b-button variant="warning" size="lg" class="btn-block">
                                        {{ stage.title }}
                                    </b-button>
                                </template>
                                <template v-if="stage.status == 'cancelled'">
                                    <b-button variant="danger" size="lg" class="btn-block">
                                        {{ stage.title }}
                                    </b-button>
                                </template> -->
                                
                            </div>
                            
                        </div>
                    </b-card>
                </div>
            </div>
        </div>
        <div class="col-2 pl-2 pt-2 pr-4">
            <b-card
                title=""
                class="">
                <b-card-text>
                    Tool version: {{ toolVersion }}
                </b-card-text>
            </b-card>
            <b-list-group class="pt-2">
                <b-list-group-item :href="guidelinesLink" variant="primary" target='_blank'><i class="fa fa-link"></i> TfNSW Climate Risk Assessment Guidelines</b-list-group-item>
                <b-list-group-item :href="hubLink" variant="primary" target='_blank'><i class="fa fa-link"></i> ESC HUB Link</b-list-group-item>
                <b-list-group-item :href="userGuideLink" variant="primary" target='_blank'><i class="fa fa-link"></i> User Guide</b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
    import { loadModules } from 'esri-loader';
    export default {
        name: 'Landing',
        data() {
            return {
                toolVersion: "1.0.0",
                aboutText: "The Climate Risk Assessment Tool (CRA Tool) is a software-based solution designed to improve resilience outcomes for the Easing Sydney Congestion program. The approach is led by climate change risk assessment guidelines including the AS5334-2013 Climate change risk assessment for settlements and infrastructure standard as well as the Transport for New South Wales (TfNSW) Climate Risk Assessment Guidelines (2021). The key objective of the CRA Tool is to inform and engage participants and support the development of climate risk assessments that drive improved resilience in the design of infrastructure. ",
                userGuide: {
                    header: 'The CRA Tool consists of five key modules to support the delivery of a risk assessment for ESC projects. These include:',
                    content: [
                        'Pre-screening - Providing the users with information about the asset and the climate change context to calibrate the master risk list to the project context. Key actions include undertaking the Asset criticality checklist and the Climate criticality checklist  and defining the relevant project assets and relevant climate hazards, which calibrate the master risk profile to the target project.',
                        'Preliminary assessment - Initial identification, review and assessment of climate change risks, controls, risk owners and stakeholders.',
                        'Detailed assessment - Detailed review of risk profile in consultation with target stakeholders and risk owners to determine risk locations and ratings.',
                        'Adaptation planning  identifying and documenting the implementation of adaptation actions to address key risks and assessing the residual risk rating for managed risks.',
                        'Project level Reporting - The ability to understand the risk profile of a specific project for reporting and communication purposes.',
                        'Program level Reporting - The ability to understand the asset risk profile for the ESC program to support reporting and communication purposes'
                    ]
                },
                stages: [
                    {
                        title: "Stage 1",
                        status: "completed"
                    },
                    {
                        title: "Stage 2",
                        status: "completed"
                    },
                    {
                        title: "Stage 3",
                        status: "onhold"
                    },
                    {
                        title: "Stage 4",
                        status: "cancelled"
                    },
                ],
                portalURL: "https://au1gis.solutions.arcadis.com/arcgis",
                appId: '8t5iT2yetDHVg8KO',
                guidelinesLink: 'https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.transport.nsw.gov.au%2Fsystem%2Ffiles%2Fmedia%2Fdocuments%2F2021%2FPlanning%2520Environment%2520%2520Sustainability%2520-%2520TfNSW%2520Climate%2520Risk%2520Assessment%2520Guidelines%2520v4.1.pdf&data=05%7C01%7Cleonardo.quines%40arcadis.com%7Cdb6e0a77c4424cf6a93508dafdc1f1c8%7C7f90057d3ea046feb07ce0568627081b%7C0%7C0%7C638101306666489513%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=fxxp%2BVx3YEaJlkev6nHFDXz7ggTYHqc%2F9JnQMbJW5CA%3D&reserved=0',
                hubLink: 'https://apps.powerapps.com/play/e/default-7f90057d-3ea0-46fe-b07c-e0568627081b/a/7b43b6d7-310d-4139-aee9-808b1316cc24?tenantId=7f90057d-3ea0-46fe-b07c-e0568627081b',
                userGuideLink: 'https://arcadiso365.sharepoint.com/:b:/r/teams/project-30107250/ProjectDocuments/1.%20PROGRAM/S-Sustainability/3.%20Admin/CCRA%20Tool%20User%20Guide%20v1.0.pdf?csf=1&web=1&e=NCtY2e'

            }
        },
        computed: {
            projectList: {
                get() {
                    return this.$store.state.mainModule.projectList
                } 
            }
        },
        methods: {
            selectProject: function(project){
                const current = this
                this.$store.commit('mainModule/setCurrentProject', project.id)
                this.$store.commit('mainModule/setStageNav', true)
                current.$store.commit("mapModule/setCurrentProjectCRAEntries", project.id)
                let getProjectActivePrimaryAssets = current.$store.dispatch("mainModule/fetchProjectActivePrimaryAssets")
                let getProjectActiveClimateHazards = current.$store.dispatch("mainModule/fetchProjectActiveClimateHazards")
                let getProjectAssetCriticalityList = current.$store.dispatch("mainModule/fetchProjectAssetCriticalityList", project.id)
                let getProjectClimateCriticalityList = current.$store.dispatch("mainModule/fetchProjectClimateCriticalityList", project.id)
                let getProjectAssessmentStakeholdersList = current.$store.dispatch("mainModule/fetchProjectAssessmentStakeholders", project.id)
                let getAssessmentStagesList = current.$store.dispatch("mainModule/fetchAssessmentStages", project.id)

                Promise.all([getProjectActivePrimaryAssets,getProjectActiveClimateHazards,getProjectAssetCriticalityList,getProjectClimateCriticalityList,getProjectAssessmentStakeholdersList,getAssessmentStagesList]).then(()=>{
                    current.$router.push({
                        path: `/assetcontext`
                    });
                })
            }
        },
        
    }
</script>

<style scoped>
.landingPage {
    overflow-y:hidden;
    overflow-x:hidden;
    max-height: 90vh;
}
</style>