<template>
  <div class="assetContextPage">
    <div class="row">
        <div class="col-9 pr-0 pt-2 pl-4">
            <b-card>
                <template #header>
                        <h1 class="">{{projectName}}</h1>
                    </template>
            
                <div class="row">
                    <div class="col pl-2 pr-2">
                        <b-card
                            title="Asset Map"
                            class="m-2">
                            <!-- <div class="mapsection">
                            </div> -->
                            <!-- <MapComponent></MapComponent> -->
                            <template v-if="sceneId">
                              <FlexMap mode="3d" :mapSceneId="sceneId" id="assetContextMap"/>
                            </template>
                            <template v-else>
                              <FlexMap mode="2d" :mapSceneId="mapId" id="assetContextMap"/>
                            </template>
                        </b-card>
                    </div>
                </div>
            </b-card>
        </div>
        <div class="col-3 pl-2 pt-2 pr-4">
            <div class="row mb-2 mt-2">
                <div class="col-1"></div>
                <div class="col-3"><h5>Status: </h5></div>
                <div class="col-8">
                    <b-form-radio-group
                        id="btn-radios-2"
                        v-model="currentStage1Status"
                        button-variant="text-dark"
                        size="sm"
                        name="radio-btn-outline"
                        buttons
                    >
                        <b-form-radio value="" class="btn-outline-danger">Not Started</b-form-radio>
                        <b-form-radio value="in_progress" class="btn-outline-warning">In Progress</b-form-radio>
                        <b-form-radio value="completed" class="btn-outline-success">Completed</b-form-radio>
                    </b-form-radio-group>
                </div>
                <!-- <div class="col-6">Status</div>
                <div class="col-6">
                    <b-form-radio-group
                        v-model="selected"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                    >
                        <b-form-radio value="not_started" class="warning">Not Started</b-form-radio>
                        <b-form-radio value="in_progress">In Progress</b-form-radio>
                        <b-form-radio value="completed">Completed</b-form-radio>
                    </b-form-radio-group>
                </div> -->
            </div>
            <b-card
                title=""
                class="">
                <template #header>
                    <h2 class="mb-0">Project Significance</h2>
                </template>
                <b-form-checkbox-group
                    v-model="modifiedAssetCriticalityList"
                    value-field="yes"
                    text-field="label"
                    stacked
                    
                >
                <b-form-checkbox v-for="(item, index) in assetCriticalityList" :key="index" :value="item.label" >{{item.label}}</b-form-checkbox>
                <!-- <template v-if="currentAssetCriticalityList.length==0">
                </template> -->
                <!-- <template v-else>
                    <b-form-checkbox v-for="(item, index) in currentAssetCriticalityList" :key="index" :value="item.yes" ><small>{{item.label}}</small></b-form-checkbox>
                </template> -->
                </b-form-checkbox-group>
                <template #footer v-if="anyChangesInAssetCriticality">
                    <b-button size="sm" variant="outline-secondary" class="mr-2" @click="resetProjectAssetCriticalityList()">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" @click="updateProjectAssetCriticalityList()">
                        Update
                    </b-button>
                </template>
            </b-card>
            <b-card header-tag="header" footer-tag="footer">
                <template #header>
                    <h6 class="mb-0">Project Scope - Applicable Asset Classes and Design Life</h6>
                </template>
                <b-table-simple sticky-header small responsive>
                    <b-thead head-variant="light">
                        <b-th>Asset Type</b-th>
                        <b-th>Design life</b-th>
                    </b-thead>
                    <b-tbody>
                        <template v-if="primaryAssetTypes.length">
                            <b-tr v-for="(item, index) in modifiedPrimaryAssetTypes" :key="index">
                                <b-td>
                                    <b-form-checkbox
                                        v-model="item.isActive"
                                    >
                                    {{ item.label }}
                                    </b-form-checkbox>
                                </b-td>
                                <b-td>
                                    <input type="number" v-model="item.designLife" />
                                </b-td>
                                
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
                <template #footer v-if="anyChangesInPrimaryAssetTypes">
                    <b-button size="sm" variant="outline-secondary" class="mr-2" @click="resetActivePrimaryAssetTypes()">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" @click="updateActivePrimaryAssetTypes()">
                        Update
                    </b-button>
                </template>

            </b-card>
            

        </div>
    </div>
    <b-modal 
        v-model="isConfirmedModal" 
        ok-title="Yes" 
        ok-variant="outline-success"
        cancel-title="No"
        cancel-variant="outline-danger"
        @cancel="isConfirmedCancel" 
        @hidden="isConfirmedCancel" 
        @ok="isConfirmedOk"
    >
        <b-card >
            Please confirm that Stage 1 is to be "Completed"?
        </b-card>
    </b-modal>
  </div>
</template>

<script>
import { baseApiUrl } from '../../config'
/*eslint-disable*/
import MapComponent from './Map'
import axios from 'axios'
import FlexMap from './FlexMap.vue'

export default {
  name: 'AssetContext',
  components: {
    MapComponent,
    FlexMap
  },
  data() {
    return {
        modifiedPrimaryAssetTypes: [],
        anyChangesInPrimaryAssetTypes: true,
        //   currentAssetCriticalityList: [],
        modifiedAssetCriticalityList: [],
        anyChangesInAssetCriticality: false,
        currentStage1Status: '',
        isConfirmed: false,
        isConfirmedModal: false
      
    }
  },
  computed: {
    projectName: {
        get(){
            this.currentStage1Status = JSON.parse(JSON.stringify(this.$store.state.mainModule.currentProject.stage_1_status));
            return this.$store.state.mainModule.currentProject.name
        }
    },
    primaryAssetTypes: {
        get() {
            this.modifiedPrimaryAssetTypes = []
            this.$store.state.mainModule.currentActivePrimaryAsset.forEach(item=>{
                this.modifiedPrimaryAssetTypes.push({...item})
            })
            return this.$store.state.mainModule.currentActivePrimaryAsset
        }
    },
    assetCriticalityList: {
        get() {
            this.modifiedAssetCriticalityList = this.$store.state.mainModule.currentProjectAssetCriticalityList.filter(obj=>obj.yes).map(item=>item.label)
            return this.$store.state.mainModule.currentProjectAssetCriticalityList
        }
    },
    sceneId: {
        get() {
            return this.$store.state.mainModule.projectMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentProject.code)[0].sceneId
        }
    },
    mapId: {
        get() {
            return this.$store.state.mainModule.projectMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentProject.code)[0].mapId
        }
    },
  },
  watch: {
        'modifiedPrimaryAssetTypes': {
            handler(newVal, oldVal) {
                if (this.equalsCheck(this.primaryAssetTypes,newVal)) {
                    this.anyChangesInPrimaryAssetTypes = false
                }
                else {
                    this.anyChangesInPrimaryAssetTypes = true
                }
            },
            deep: true
        }, 
        modifiedAssetCriticalityList: function(newVal, oldVal) {
            let currentAssetCriticality = this.assetCriticalityList.filter(obj=>obj.yes).map(item=>item.label)
            if (this.sameMembers(currentAssetCriticality, newVal)) {
                this.anyChangesInAssetCriticality = false
            }
            else {
                this.anyChangesInAssetCriticality = true
            }
        },
        currentStage1Status: function(newVal, oldVal) {
            if (newVal == "completed" && newVal != this.$store.state.mainModule.currentProject.stage_1_status && !this.isConfirmed) {
                this.isConfirmedModal = true
            }
            else {
                if (newVal != this.$store.state.mainModule.currentProject.stage_1_status) {
                    let data = {
                        projectId: this.$store.state.mainModule.currentProjectId,
                        stage: 'stageOne',
                        stageStatus: newVal
                    }
                    let url = baseApiUrl + 'projectStageStatus'
                    let config  = {
                        responseType: 'json'
                    }
                    axios.put(url,data,config).then(()=>{
                        this.$notify({
                            group: 'successNotifications',
                            title: "Project Stage One Status Update",
                            type: 'success',
                            text: "Update to the project stage one status completed.",
                            width: 500,
                            duration: 3000
                        })
                        // update project list and project details
                        this.$store.dispatch('mainModule/fetchProjects').then(()=>{
                            this.$store.commit('mainModule/setCurrentProject',this.$store.state.mainModule.currentProjectId)
                        })
                    })
                    .catch((err)=>{
                        this.$notify({
                            group: 'errors',
                            title: "No Update done",
                            type: 'error',
                            text: "No Update to the project stage one status completed",
                            width: 500,
                            duration: 3000
                        })
                    })
                }

            }
        }

  },
  methods: {
    resetActivePrimaryAssetTypes() {
        this.modifiedPrimaryAssetTypes = []
        this.primaryAssetTypes.forEach(item=>{
            this.modifiedPrimaryAssetTypes.push({...item})
        })
    },
    updateActivePrimaryAssetTypes() {
        const current = this
        this.$store.dispatch("mainModule/updateActivePrimaryAssetTypes", current.modifiedPrimaryAssetTypes).then(()=>{
            this.$store.dispatch("mainModule/fetchProjectActivePrimaryAssets", this.$store.state.mainModule.currentProjectId).then(()=>{
                
                current.anyChangesInPrimaryAssetTypes = false
                this.$notify({
                    group: 'successNotifications',
                    title: "Project Primary Assets Update",
                    type: 'success',
                    text: "Update to the Project Primary Assets completed.",
                    width: 500,
                    duration: 3000
                })
                this.$store.dispatch('mapModule/fetchCRAEntries').then(()=>{
                    this.$store.commit("mapModule/setCurrentProjectCRAEntries", this.$store.state.mainModule.currentProjectId)
                    if (this.currentStage1Status == '') {
                        this.currentStage1Status = 'in_progress'
                    }
                })
            })        
        })
    },
    resetProjectAssetCriticalityList() {
        this.modifiedAssetCriticalityList = this.$store.state.mainModule.currentProjectAssetCriticalityList.filter(obj=>obj.yes).map(item=>item.label)
    },
    updateProjectAssetCriticalityList() {
        // send updated data to back-end
        let data = {
            projectAssetCriticalityList: this.modifiedAssetCriticalityList,
            projectId: this.$store.state.mainModule.currentProjectId
        }
        let url = baseApiUrl + 'projectAssetCriticalityList'
        let config  = {
            responseType: 'json'
        }
        axios.put(url, data, config).then(()=>{
            this.$notify({
                group: 'successNotifications',
                title: "Project Criticality Update",
                type: 'success',
                text: "Update to the Project Criticality Checklist completed.",
                width: 500,
                duration: 3000
            })
            this.$store.dispatch("mainModule/fetchProjectAssetCriticalityList", this.$store.state.mainModule.currentProjectId).then(()=>{
                this.anyChangesInAssetCriticality = false
                if (this.currentStage1Status == '') {
                    this.currentStage1Status = 'in_progress'
                }
            })
        })

        // update list values
        // advise details via notify
    },
    containsAll: function(arr1, arr2) {
        return arr2.every(arr2Item => arr1.includes(arr2Item))
    },
    sameMembers: function(arr1, arr2) {
        return this.containsAll(arr1, arr2) && this.containsAll(arr2, arr1);
    },
    equalsCheck: function(a, b) {
        return JSON.stringify(a) === JSON.stringify(b);
    },
    isConfirmedCancel: function () {
        if (!this.isConfirmed) {
            setTimeout(() => {
                this.currentStage1Status = this.$store.state.mainModule.currentProject.stage_1_status
            }, 300);
            this.isConfirmed = false
        }
        this.isConfirmedModal = false
    },
    isConfirmedOk: function () {
        setTimeout(() => {
            this.currentStage1Status = this.$store.state.mainModule.currentProject.stage_1_status
        }, 300);
        this.isConfirmed = true
        this.isConfirmedModal = false
        setTimeout(() => {
            this.currentStage1Status = "completed"
        }, 300);
    }
  },
  mounted () {
    const current = this
  }
}
</script>

<style scoped>
.data-scroll {
  overflow: auto;
  max-height: 20vh;
}
#assetContextMap {
    height: 70vh;
}
.table-responsive {
    max-height:35vh;
}
.assetContextPage {
    overflow:hidden;
    max-height: 89vh;
}
</style>